import React, { useState, useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { RiParkingBoxFill } from "react-icons/ri";
import { TfiEmail } from "react-icons/tfi";
import "./kontakt.css";
/*import CookieConsent from "react-cookie-consent"; */

const Kontakt = () => {

    const [cookieAccepted, acceptCookies] = useState(false);

    useEffect(() => {
        const data = window.localStorage.getItem("CookiesAccepted");
        if (data !== null) acceptCookies(JSON.parse(data))
    }, [])

    useEffect(() => {
        window.localStorage.setItem("CookiesAccepted", JSON.stringify(cookieAccepted))
    }, [cookieAccepted])

    return (
        <>

            <div className="kontakt_headline">
                <div className="kontakt__headline">
                    <h1>
                        So erreichen Sie unsere kieferorthopädische Praxis in Wolfenbüttel (Linden)
                    </h1>
                </div>
            </div>

            {cookieAccepted ?
                <div className="kontakt">
                    <div className="content_gmaps_adress_container">
                        <div className="content_gmaps">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1334.8812229389277!2d10.561536755567047!3d52.14918330431381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a55e74078032b3%3A0xd3c1bb4fe8e66e6a!2sIn%20den%20Sch%C3%B6nen%20Morgen%2010%2C%2038300%20Wolfenb%C3%BCttel!5e0!3m2!1sde!2sde!4v1699034613898!5m2!1sde!2sde" title="gmaps"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                alt="Bevor sie Google Maps nutzen, akzeptieren Sie die dafür notwendigen Cookies"
                                data-cookiecategory="functionality">
                            </iframe>
                        </div>

                        <div className="content_adress">
                            <div className="content__adress">
                                <p>
                                    <strong>
                                        Fachzahnarztpraxis für Kieferorthopädie <br></br>Dr. Ch. Medefindt<br></br><br></br>
                                    </strong>
                                    In den Schönen Morgen 10 <br></br>
                                    <span>
                                        38300 Wolfenbüttel
                                    </span>
                                    <br></br>
                                    <br></br>
                                </p>
                                <p>
                                    <a href="tel:+4953316041"><FaPhoneAlt className="kontakt_phone_icon" style={{ verticalAlign: "middle" }}> </FaPhoneAlt> </a> <a href="tel:+4953316041"> +495331 6041 </a><br></br>
                                </p>
                                <p>
                                    <a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> <TfiEmail className="kontakt_email_icon" style={{ verticalAlign: "middle" }}> color={"#056b70ff"} </TfiEmail> </a> <a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> info@wolfenbuettel-kieferorthopaedie.de </a> <br></br>
                                </p>
                            </div>

                            <div className="content_sep">
                                <hr className="content__sep"></hr>
                            </div>

                            <div className="kontakt_content__buttons">
                                <p>
                                    <RiParkingBoxFill className="kontakt_parking" style={{ verticalAlign: "middle" }}>  </RiParkingBoxFill> Praxiseigene Parkplätze verfügbar
                                </p>
                            </div>

                            <a href="https://iie-systems.de/online-termin-kfo-medefindt" target="_blank" rel="noreferrer">
                                <button title="onlineAppointments" className="kontakt_btn">
                                    Termin online buchen
                                </button>
                            </a>

                        {/*
                            <button title="onlineAppointments" className="kontakt_btn" onClick={window.toggleDrFlexAppointments}>
                                Termin online buchen
                            </button>
                        */}
                        </div>
                    </div>
                </div>


                :


                <div className="kontakt">
                    <div className="content_gmaps_adress_container">
                        <div className="content_gmaps">
                            <div className="cookieBanner_gmaps">
                                <p>
                                    Aus datenschutzrechtlichen Gründen benötigen wir Ihre Einwilligung um Inhalte von GoogleMaps laden zu können
                                </p>
                            </div>
                            <button title="googleMaps" className="gmaps_button" onClick={() => acceptCookies((prev) => !prev)}>
                                <p>
                                    Einverstanden
                                </p>
                            </button>
                        </div>

                        <div className="content_adress">
                            <div className="content__adress">
                                <p>
                                    <strong>
                                        Fachzahnarztpraxis für Kieferorthopädie <br></br>Dr. Ch. Medefindt<br></br><br></br>
                                    </strong>
                                    In den Schönen Morgen 10 <br></br>
                                    <span>
                                        38300 Wolfenbüttel
                                    </span>
                                    <br></br>
                                    <br></br>
                                </p>
                                <p>
                                    <a href="tel:+4953316041"><FaPhoneAlt className="kontakt_phone_icon" style={{ verticalAlign: "middle" }}> </FaPhoneAlt> </a> <a href="tel:+4953316041"> +495331 6041 </a><br></br>
                                </p>
                                <p>
                                    <a href="E-Mail"> <TfiEmail className="kontakt_email_icon" style={{ verticalAlign: "middle" }}> color={"#056b70ff"} </TfiEmail> </a> <a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> info@wolfenbuettel-kieferorthopaedie.de </a> <br></br>
                                </p>
                            </div>

                            <div className="content_sep">
                                <hr className="content__sep"></hr>
                            </div>

                            <div className="kontakt_content__buttons">
                                <p>
                                    <RiParkingBoxFill className="kontakt_parking" style={{ verticalAlign: "middle" }}>  </RiParkingBoxFill> Praxiseigene Parkplätze verfügbar
                                </p>
                            </div>

                            <a href="https://iie-systems.de/online-termin-kfo-medefindt" target="_blank" rel="noreferrer">
                                <button title="onlineAppointments" className="kontakt_btn">
                                    Termin online buchen
                                </button>
                            </a>

                            {/* 
                            <button title="onlineAppointments" className="kontakt_btn" onClick={window.toggleDrFlexAppointments}>
                                Termin online buchen
                            </button>
                        */}
                        </div>

                    </div>
                </div>
            }

            <div className="genContainerFooter">
                <div className="genContainerFooter_inner">
                    <p>
                        Wir freuen uns auf Ihren Besuch
                    </p>
                </div>
            </div>
        </>
    )
}

export default Kontakt