import React from "react";
import "./impressum.css";
import "animate.css"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Impressum = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>
            <div className="impressum">
                <div className="impressum_content">
                    <h1> Impressum </h1>
                </div>

                <div className="impressum_content">
                    <p><strong>Pflichtangaben gemäß § 5 des Telemediengesetzes</strong></p><br></br>
                </div>

                <div className="impressum_content">
                    <p><strong>Diensteanbieter</strong></p><br></br>
                    <p> Dr. med. dent. Christine Clara Sophie Medefindt </p>
                </div>

                <div className="impressum_content">
                    <p><strong> Anschrift & Kontaktdaten </strong></p><br></br>
                    <p>
                        Praxis für Kieferorthopädie Dr. Ch. Medefindt <br></br>
                        In den Schönen Morgen 10 <br></br>
                        38300 Wolfenbüttel
                    </p>
                    <br></br>
                    <p> Telefon: +495331 6041 </p>
                    <p> E-Mail: info@wolfenbuettel-kieferorthopaedie.de </p>
                </div>

                <div className="impressum_content">
                    <p>
                        <strong> Zuständige Kassenärztliche Vereinigung </strong>
                    </p>
                    <br></br>
                    <p>
                        Kassenzahnärztliche Vereinigung Niedersachsen (KZVN) <br></br>
                        Zeißstraße 11 <br></br>
                        30519 Hannover <br></br><br></br>
                        Telefon: 0511 84050
                    </p>
                    <p>
                        Mail:<a href="mailto:info@kzvn.de"> &nbsp; info@kzvn.de </a>
                    </p>
                    <p>
                        Website: &nbsp; <a href="https://www.kzvn.de/startseite.html"> https://www.kzvn.de </a>
                    </p>
                </div>

                <div className="impressum_content">
                    <p>
                        <strong> Zuständige Kammer </strong>
                    </p>
                    <br></br>
                    <p>
                        Zahnärztekammer Niedersachsen (ZÄKN) <br></br>
                        Zeißstraße 11A 
                        <br></br>
                        30519 Hannover 
                        <br></br><br></br>
                        Telefon: 0511 833910 
                        <br></br>
                    </p>
                    <p>
                        Mail:&nbsp; <a href="mailto:info@zkn.de"> info@zkn.de </a>
                    </p>
                    <p>
                        Website: &nbsp; <a href="https://zkn.de"> https://zkn.de </a>
                    </p>
                </div>

                <div className="impressum_content">
                    <p><strong> Gesetzliche Berufsbezeichnung </strong></p><br></br>
                    <p> Dr. med. dent. Christine Medefindt Fachzahnärztin für Kieferorthopädie </p>
                    <p> (verliehen in der Bundesrepublik Deutschland) </p>
                </div>

                <div className="impressum_content">
                    <p><strong> Berufsrechtliche Regelungen </strong></p><br></br>
                    <p>
                        Zahnheilkundegesetz <br></br>
                        Gebührenordnung für Zahnärzte <br></br>
                        Berufsordnung für Zahnärzte <br></br>
                        Niedersächsisches Kammergesetz für die Heilberufe (HKG)
                    </p><br></br>

                    <p> Weitere Informationen finden sie unter: </p>
                    <p>
                        <a href="https://zkn.de"> https://zkn.de/ </a>
                    </p>
                </div>

                {/*<div data-aos="fade-up" className="impressum_content">
                    <p><strong> Haftungsbeschränkung für Inhalte dieser Website </strong></p><br></br>
                    <p>
                    </p>
                </div>

                <div data-aos="fade-up" className="impressum_content">
                    <p><strong> Haftungsbeschränkung für Links </strong></p><br></br>
                    <p>
                    </p>
    </div>*/}

            </div>
        </>
    )
}

export default Impressum