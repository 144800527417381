import React from "react";
import "./leistungen.css";
import "animate.css"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import aligner from "../../assets/aligner.jpg"
import beratung from "../../assets/beratung_img.jpg"
import diagnostik from "../../assets/diagnostik_intraoralscanner.JPG"
import fruehbehandlung from "../../assets/fruehbehandlung.JPG"
import jugendliche from "../../assets/jugendliche.JPG"

const Leistungen = () => {

    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
    }, []);

    return (
        <>

            <div className="leistungen_headline">
                <div className="leistungen__headline">
                    <h1>
                        Unsere Leistungen
                    </h1>
                </div>
            </div>

            <div id="separator" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <div className="home_outter_container_block6">
                    <div className="home_inner_container_block6_headline">
                        <p>
                            Beratung
                        </p>
                    </div>
                </div>
                <div className="home_outter_container_block6_content">
                    <div className="home_inner_container_block6_content">
                        <div className="home_inner_container_content_section1">
                            <p>
                                Unser Anliegen ist es, Ihnen eine bestmögliche Beratung
                                und detaillierte Aufklärung zu bieten, ob eine Behandlungsnotwendigkeit
                                besteht und wenn ja, welche Behandlung für Sie oder Ihr Kind die
                                individuell Beste ist. Außerdem nehmen wir uns viel Zeit für Ihre
                                Fragen. Wir erklären Ihnen ausführlich welche Alternativen es gibt,
                                wie der Behandlungsablauf ist, ob Ihre Krankenversicherung sich an den
                                Kosten beteiligt und wann der beste Zeitpunkt für den Behandlungsstart
                                ist. Wichtig ist uns auch Ihre spezifischen Wünsche und Bedürfnisse
                                zu berücksichtigen.
                            </p>
                        </div>
                        <div className="home_inner_container_block6_image">
                            <img src={beratung} alt="Beratung" loading="lazy"></img>
                        </div>
                    </div>
                </div>
                <div className="home_separator1">
                </div>
            </div>

            <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <div className="home_outter_container_block7">
                    <div className="home_inner_container_block7_headline_content">
                        <p>
                            Diagnostik
                        </p>
                    </div>
                </div>

                <div className="home_outter_container_block7_content">
                    <div className="home_inner_container_block7_content_bg_img">
                        <div className="home_inner_container_block7_bg_img">
                            <img src={diagnostik} alt="Beratung" loading="lazy"></img>
                        </div>
                        <div className="home_inner_container_block7_content_content">
                            <p>
                                Bevor wir mit der Behandlung starten, steht zunächst
                                eine umfassende Diagnostik an. Hierfür benötigen wir Kiefermodelle
                                der derzeitigen Bisssituation, Röntgenbilder und Fotos der Zähne und
                                des Gesichts. Wir arbeiten mit höchstmodernen, strahlungsarmen Geräten.
                                Zudem röntgen wir auch nur dann, wenn es wirklich nötig ist. Nach
                                einer detaillierten Auswertung der diagnostischen Unterlagen erstellen
                                wir einen individuellen Behandlungsplan, den wir anschließend mit Ihnen
                                in einem Folgetermin durchgehen und gemeinsam die für Sie bestmöglichen
                                Therapieoptionen zur Korrektur der Zahnfehlstellung besprechen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="separator" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <div className="home_separator2">
                </div>

                <div className="home_outter_container_block6">
                    <div className="home_inner_container_block6_headline">
                        <p>
                            Kinder
                        </p>
                    </div>
                </div>

                <div className="home_outter_container_block6_content">
                    <div className="home_inner_container_block6_content">
                        <div className="home_inner_container_content_section1">
                            <p>
                                Wir empfehlen Ihnen eine Erstuntersuchung
                                schon zwischen dem 4. und 6. Lebensjahr. In den
                                meisten Fällen ist aber so früh noch keine Behandlung
                                erforderlich. Bei regelmäßiger Kontrolle der Zahn- und
                                Kieferentwicklung können wir dann, falls notwendig, den
                                bestmöglichen Zeitpunkt bestimmen, um mit einer
                                kieferorthopädischen Behandlung zu beginnen.
                                <br></br>
                                <br></br>
                                In Ausnahmefällen beginnt die Behandlung bei starken
                                Fehlstellungen bereits im Milchgebiss frühestens allerdings
                                ab dem 4. Lebensjahr. Ungünstige Angewohnheiten, wie zum
                                Beispiel das Daumenlutschen oder eine falsche Zungenlage,
                                können zu Zahn- und Kieferfehlstellungen führen. Üblicherweise
                                findet der Einsatz einer Zahnspange im Alter zwischen 8 und 9
                                Jahren statt. Ein rechtzeitiges Eingreifen ermöglicht ein
                                normales Wachstum und erspart gegebenenfalls eine spätere,
                                aufwändigere Behandlung. Die Korrektur der Fehlstellung erfolgt
                                hierbei meistens mit einer herausnehmbaren Zahnspange und
                                dauert ungefähr 1,5 Jahre.
                                <br></br>
                                <br></br>
                                Bei einem vorzeitigen Milchzahnverlust durch einen Unfall
                                oder Karies kommt es zu Lücken, bei denen umgehend ein
                                Lückenhalter eingesetzt werden sollte, damit dem nachfolgenden,
                                bleibenden Zahn ausreichend Platz für den späteren Zahndurchbruch
                                geboten wird.
                            </p>
                        </div>
                        <div className="home_inner_container_block6_image">
                            <img src={fruehbehandlung} alt="Beratung" loading="lazy"></img>
                        </div>
                    </div>
                </div>

                <div className="leistungen_separator_opacity">
                </div>
            </div>

            <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <div className="home_outter_container_block7">
                    <div className="home_inner_container_block7_headline_content">
                        <p>
                            Jugendliche
                        </p>
                    </div>
                </div>

                <div className="home_outter_container_block7_content">
                    <div className="home_inner_container_block7_content_bg_img">
                        <div className="home_inner_container_block7_bg_img">
                            <img src={jugendliche} alt="Beratung" loading="lazy"></img>
                        </div>
                        <div className="home_inner_container_block7_content_content">
                            <p>
                                Der günstigste Zeitpunkt, eine kieferorthopädische Behandlung zu beginnen,
                                liegt zwischen dem 10. und 14. Lebensjahr. In dieser Zeit brechen die seitlichen
                                bleibenden Zähne durch und der Körper Ihres Kindes erfährt einen enormen Wachstumsschub,
                                der während der Therapie sehr gut ausgenutzt werden kann. Wir legen größten Wert darauf Ihrem
                                Kind auf Augenhöhe zu begegnen und die einzelnen Behandlungsschritte und -ziele so verständlich wie
                                möglich zu formulieren.
                                <br></br>
                                <br></br>
                                Oftmals sind lockere, herausnehmbare Zahnspangen, der erste Schritt, der in Vorbereitung auf
                                die kieferorthopädische Behandlung mit einer festen Zahnspange, gegangen wird. Diese Spangen
                                kommen in der Regel zum Einsatz, solange sich Ihr Kind noch im Wachstum befindet.
                                <br></br>
                                <br></br>
                                Während die lockere Zahnspange Einfluss auf das Wachstum der Kiefer hat, ist die feste
                                Zahnspange notwendig, um die einzelnen Zähne samt ihrer Wurzeln in die gewünschte Position zu
                                bringen.
                                <br></br>
                                Je nach Zahnfehlstellung können bereits im Jugendalter die modernen, transparenten Kunststoffschienen
                                zum Einsatz kommen. Alle ein bis zwei Wochen wechselt man zu einer neuen Schiene und kommt somit Stück
                                für Stück der gewünschten Zahnstellung näher. Die Schienen werden nicht fest an den Zähnen angebracht und
                                können somit zum Essen und für die Zahnpflege herausgenommen werden.
                                <br></br>
                                <br></br>
                                Die Zähne haben nach der Behandlung immer die Tendenz in ihre Urpsrungsposition zurückkehren zu wollen.
                                Zur Stabilisierung des Behandlungsergebnisses empfehlen wir daher immer einen festsitzenden Retainer. Der
                                Retainer ist ein dünner Metalldraht, der „unsichtbar“ hinter den Zähnen angebracht wird.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="separator" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <div className="home_separator2">
                </div>

                <div className="home_outter_container_block6">
                    <div className="home_inner_container_block6_headline">
                        <p>
                            Erwachsene
                        </p>
                    </div>
                </div>

                <div className="home_outter_container_block6_content">
                    <div className="home_inner_container_block6_content">
                        <div className="home_inner_container_content_section1">
                            <p>
                                Die Korrektur einer Zahnfehlstellung kennt keine Altersgrenze. Die Gründe hierfür sind vielfältig: eine störende Ästhetik, eine unterlassene Behandlung in der
                                eigenen Jugend, Schwierigkeiten beim Zähneputzen durch einen Zahnengstand, die Vorbereitung für
                                eine prothetische Versorgung oder vieles mehr.
                                <br></br>
                                <br></br>
                                Je nach Zahn- und Kieferfehlstellung und Ihren speziellen Bedürfnissen und persönlichen Wünschen
                                erarbeiten wir zusammen einen Behandlungsplan. In einem individuellen Beratungsgespräch zeigen wir
                                Ihnen moderne und passende Methoden und finden sicher eine Lösung, die sich gut mit der eigenen
                                Lebenssituation vereinbaren lässt. Neben der herkömmlichen festen Zahnspange gibt es eine weitere
                                “unsichtbare“ Alternative, die so genannten Aligner (durchsichtige Schienen). Zusätzlich zur
                                Schienentherapie bieten wir unseren erwachsenen Patienten auch die Möglichkeit, Zahnfehlstellungen mit
                                unauffälligen, zahnfarbenen Keramikbrackets korrigieren zu lassen.
                                <br></br>
                                <br></br>
                                Da das Kieferwachstum bei erwachsenen Patienten bereits abgeschlossen ist, ist eine gravierende
                                Fehlstellung manchmal nur in Kombination mit einer chirurgischen Dysgnathieoperation, also einer
                                operativen Kieferumstellung, möglich. Eine alleinige kieferorthopädische Behandlung würde zu unbefriedigenden
                                und instabilen Ergebnissen führen. Hierfür arbeiten wir mit erfahrenen Experten der Mund-, Kiefer-
                                Gesichtschirurgie zusammen und planen die erforderlichen Schritte in gemeinsamer Abstimmung.
                            </p>
                        </div>
                        <div className="home_inner_container_block6_image">
                            <img src={aligner} alt="Beratung" loading="lazy"></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer">
            </div>

            <div className="genContainerFooter">
                <div className="genContainerFooter_inner">
                    <p>
                        Sie haben noch offene Fragen? <br></br> Rufen Sie uns gerne an
                    </p>
                </div>
            </div>
        </>
    )
}

export default Leistungen