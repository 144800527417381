import "./gallery.css"
import CloseIcon from '@mui/icons-material/Close';
import img1 from "./gallerie1.JPG";
import img2 from "./gallerie2.JPG";
import img3 from "./gallerie3.JPG";
import img4 from "./gallerie4.JPG";
import img5 from "./gallerie5.JPG";
import img6 from "./gallerie6.JPG";
import img7 from "./gallerie7.JPG";
import img8 from "./gallerie8.JPG";
import img9 from "./gallerie9.JPG";
import img10 from "./gallerie10.JPG";
import img11 from "./gallerie11.JPG";
import img12 from "./gallerie12.JPG";
import img14 from "./gallerie14.jpg";
import img15 from "./gallerie15.JPG";
import img16 from "./gallerie16.JPG";
import img17 from "./gallerie17.JPG";
import img18 from "./gallerie18.JPG";
import img19 from "./gallerie19.JPG";
import img20 from "./gallerie20.JPG";
import img21 from "./gallerie21.JPG";
import img22 from "./gallerie22.JPG";
import img23 from "./gallerie23.JPG";
import { useState } from "react";

const Gallery = () => {

    let data = [
        {
            "id": 1,
            "alt": "Empfangsbereich",
            "title": "Empfangsbereich",
            "image": img1,
            "type": "image/jpeg"
        },
        {
            "id": 2,
            "alt": "Untersuchung",
            "title": "Untersuchung",
            "image": img2,
            "type": "image/jpeg"
        },
        {
            "id": 3,
            "alt": "Wartezimmer Kinderecke",
            "title": "Wartezimmer Kinderecke",
            "image": img3,
            "type": "image/jpeg"
        },
        {
            "id": 4,
            "alt": "Empfang",
            "title": "Empfang",
            "image": img4,
            "type": "image/jpeg"
        },
        {
            "id": 5,
            "alt": "Wartezimmer Kinderecke",
            "title": "Wartezimmer Kinderecke",
            "image": img5,
            "type": "image/jpeg"
        },
        {
            "id": 6,
            "alt": "Wartezimmer",
            "title": "Wartezimmer",
            "image": img6,
            "type": "image/jpeg"
        },
        {
            "id": 7,
            "alt": "Flur/Beratung",
            "title": "Flur/Beratung",
            "image": img7,
            "type": "image/jpeg"
        },
        {
            "id": 8,
            "alt": "Unsere Einheit",
            "title": "Unsere Einheit",
            "image": img8,
            "type": "image/jpeg"
        },
        {
            "id": 9,
            "alt": "Gaumenspange",
            "title": "Gaumenspange",
            "image": img9,
            "type": "image/jpeg"
        },
        {
            "id": 10,
            "alt": "Digitales Röntgengerät",
            "title": "Digitales Röntgengerät",
            "image": img10,
            "type": "image/jpeg"
        },
        {
            "id": 11,
            "alt": "Dental Modell",
            "title": "Dental Modell",
            "image": img11,
            "type": "image/jpeg"
        },
        {
            "id": 12,
            "alt": "Behandlungszimmer",
            "title": "Behandlungszimmer",
            "image": img12,
            "type": "image/jpeg"
        },
        {
            "id": 14,
            "alt": "Beratung",
            "title": "Beratung",
            "image": img14,
            "type": "image/jpeg"
        },
        {
            "id": 15,
            "alt": "Empfangsbereich",
            "title": "Empfangsbereich",
            "image": img15,
            "type": "image/jpeg"
        },
        {
            "id": 16,
            "alt": "Aligner",
            "title": "Aligner",
            "image": img16,
            "type": "image/jpeg"
        },
        {
            "id": 17,
            "alt": "Kieferorthopädische Praxis (Außenansicht)",
            "title": "Kieferorthopädische Praxis (Außenansicht)",
            "image": img17,
            "type": "image/jpeg"
        },
        {
            "id": 18,
            "alt": "Behandlungseinheit",
            "title": "Behandlungseinheit",
            "image": img18,
            "type": "image/jpeg"
        },
        {
            "id": 19,
            "alt": "Behandlungseinheit DKL",
            "title": "Behandlungseinheit DKL",
            "image": img19,
            "type": "image/jpeg"
        },
        {
            "id": 20,
            "alt": "Wartezimmer",
            "title": "Wartezimmer",
            "image": img20,
            "type": "image/jpeg"
        },
        {
            "id": 21,
            "alt": "Wartezimmer",
            "title": "Wartezimmer",
            "image": img21,
            "type": "image/jpeg"
        },
        {
            "id": 22,
            "alt": "Visitenkarten",
            "title": "Visitenkarten",
            "image": img22,
            "type": "image/jpeg"
        },
        {
            "id": 23,
            "alt": "Intraoralscanner",
            "title": "Intraoralscanner",
            "image": img23,
            "type": "image/jpeg"
        }
    ]

    const [model, setModel] = useState(false);

    const [tempImage, setTempImage] = useState("");

    const getImg = (image) => {
        setTempImage(image);
        setModel(true);
    }

    return (
        <>
            <div className={model ? "model open" : "model"}>
                <img src={tempImage} alt="tempImage" />
                <CloseIcon onClick={() => setModel(false)} />
            </div>

            <div className="outter_container">
                <div className="inner_container">
                    <div className="gallery">
                        {data.map((source, index) => {
                            return (
                                <div className="gallery_images" key={index} onClick={() => getImg(source.image)}>
                                    <img src={source.image} alt={source.alt} loading="lazy" title={source.title} style={{ width: "100%" }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )

}

export default Gallery