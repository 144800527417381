import React from 'react'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import CallIcon from '@mui/icons-material/Call';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import "./buttons.css"

const Buttons = () => {
  return (
    <div className="buttons_main">
      <div className="buttons_sub">
        <a href="tel:+4953316041">
          <Box>
            <Fab title="callButton" variant="extended" size="large" sx={{ bgcolor: "#b2a365de;", color: "white", fontSize: 13, fontFamily: "Barlow", textTransform: "none", p: "0px 70px 0px 12px" }}>
              <CallIcon
                sx={{ color: "white", fontSize: 30, mr: "0px" }} />
            </Fab>
          </Box>
        </a>
      </div>

      <div className="buttons_sub">
        <a href="https://iie-systems.de/online-termin-kfo-medefindt" target="_blank" rel="noreferrer">
          <Box>
            <Fab title="onlineBooking" variant="extended" size="large" sx={{ bgcolor: "#b2a365de;", fontSize: 13, color: "white", fontFamily: "Barlow", textTransform: "none", p: "0px 70px 0px 13px" }}>
              <CalendarMonthIcon sx={{ color: "white", fontSize: 30, mr: "0px" }} />
            </Fab>
          </Box>
        </a>
      </div>

      {/*
        <div className="buttons_sub" onClick={window.toggleDrFlexAppointments}>
          <Box>
            <Fab title="onlineBooking" variant="extended" size="large" sx={{ bgcolor: "#b2a365de;", fontSize: 13, color: "white", fontFamily: "Barlow", textTransform: "none", p: "0px 70px 0px 13px" }}>
              <CalendarMonthIcon sx={{ color: "white", fontSize: 30, mr: "0px" }} />
            </Fab>
          </Box>
        </div>
      */}
    </div>
  )
}

export default Buttons