import React from "react";
import "./datenschutz.css";

const Datenschutz = () => {
    return (
        <>
            <div className="datenschutz">
                <div className="datenschutz_width">
                    <div className="datenschutz_main">
                        <h1> Datenschutzerklärung </h1>
                    </div>
                    <div className="datenschutz_headline_h2">
                        <h2>
                            <strong>
                                1. Datenschutz auf einen Blick
                            </strong>
                        </h2>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Allgemeine Hinweise
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                            persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
                            Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Datenerfassung auf dieser Website
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Wer ist verantwortlich für die Datenerfassung auf dieser Website? <br></br>
                            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                            können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.<br></br><br></br>
                            Wie erfassen wir Ihre Daten? <br></br>
                            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
                            Daten handeln, die Sie in ein Kontaktformular eingeben.<br></br><br></br>
                            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme
                            erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
                            des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.<br></br><br></br>
                            Wofür nutzen wir Ihre Daten? <br></br>
                            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden. <br></br><br></br>
                            Welche Rechte haben Sie bezüglich Ihrer Daten? <br></br>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                            gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                            können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
                            bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. <br></br><br></br>
                            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Analyse-Tools und Tools von Drittanbietern
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
                            allem mit sogenannten Analyseprogrammen.
                        </p>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
                            Datenschutzerklärung.<br></br>
                        </p>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Wir verwenden Anwendungen von iie-systems GmbH & Co. KG, Georgstrasse 24, 49809.
                            Durch diese Anwendungen können Patienten online über die Webseite <br></br><br></br>

                            1. Online Termine vereinbaren<br></br>

                            2. Eine digitale Sprechstunde nutzen. (Gilt nur für Anwender mit der Anwendung my Images) <br></br><br></br>

                            Weiterhin nutzen wir in der Praxis einen Terminservice zur Patienteninformation per E-Mail.<br></br><br></br>
                            Weitere Hinweise finden sie unter folgendem Link:<br></br> 
                            <a href="https://www.iie-systems.de/service/datenschutz/">https://www.iie-systems.de/service/datenschutz/</a>
                        </p>
                    </div>
                    <div className="datenschutz_headline_h2">
                        <h2>
                            <strong>
                                2. Hosting
                            </strong>
                        </h2>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Externes Hosting
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden,
                            werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen,
                            Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe
                            und sonstige Daten, die über eine Website generiert werden, handeln.
                        </p>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
                            bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
                            Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
                            Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
                            Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
                            von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
                            Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar. <br></br> <br></br>
                            Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
                            Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.<br></br> <br></br>
                            Wir setzen folgende(n) Hoster ein:<br></br> <br></br>
                            HOSTINGER operations, UAB
                            Švitrigailos str. 34, Vilnius 03230 Lithuania
                            Phone: +37064503378
                            Email: domains@hostinger.com
                        </p>
                    </div>
                    <div className="datenschutz_headline_h2">
                        <h2>
                            <strong>
                                3. Allgemeine Hinweise und Pflichtinformationen
                            </strong>
                        </h2>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Datenschutz
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                            personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
                            dieser Datenschutzerklärung.<br></br> <br></br>
                            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                            Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                            Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
                            und zu welchem Zweck das geschieht.<br></br> <br></br>
                            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
                            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                            möglich.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Hinweis zur verantwortlichen Stelle
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: <br></br><br></br>
                            Dr. med. dent. Christine Clara Sophie Medefindt <br></br>
                            Fachzahnarztpraxis für Kieferorthopädie Dr. Christine Medefindt <br></br>
                            In den Schönen Morgen 10 <br></br>
                            38300 Wolfenbüttel<br></br><br></br>
                            Tel.: +495331 6041<br></br>
                            <a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> info@wolfenbuettel-kieferorthopaedie.de </a> <br></br><br></br>
                            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
                            die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
                            entscheidet.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Speicherdauer
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
                            Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
                            berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
                            werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
                            personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
                            letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
                            Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
                            nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
                            personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
                            49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
                            Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
                            auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
                            Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
                            Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
                            zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
                            Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
                            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
                            Absätzen dieser Datenschutzerklärung informiert.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Empfänger von personenbezogenen Daten
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei
                            ist teilweise auch eine Übermittlung von personenbezogenen Daten an diese externen Stellen erforderlich.
                            Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer
                            Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten
                            an Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe
                            haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
                            Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines gültigen
                            Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über
                            gemeinsame Verarbeitung geschlossen.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Widerruf Ihrer Einwilligung zur Datenverarbeitung
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                            bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                            Datenverarbeitung bleibt vom Widerruf unberührt.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
                                Direktwerbung (Art. 21 DSGVO)
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
                            ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
                            SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
                            WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
                            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
                            WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
                            SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
                            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
                            VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                            RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).<br></br><br></br>

                            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
                            SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
                            BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                            EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
                            VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
                            ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                            NACH ART. 21 ABS. 2 DSGVO).
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Beschwerderecht bei der zuständigen Aufsichtsbehörde
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
                            verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Recht auf Datenübertragbarkeit
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Auskunft, Berichtigung und Löschung
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                            Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
                            Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
                            zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Recht auf Einschränkung der Verarbeitung
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
                            folgenden Fällen: <br></br><br></br>
                            <ul>
                                <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
                                    in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                                    Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                </li>
                                <li>
                                    Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
                                    statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
                                </li>
                                <li>
                                    Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                                    Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                                    Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                </li>
                                <li>
                                    Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                                    Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                                    überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                                    zu verlangen.
                                </li>
                            </ul>
                            <br></br><br></br>
                            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
                            ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                            Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                            juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
                            eines Mitgliedstaats verarbeitet werden.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                SSL- bzw. TLS-Verschlüsselung
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
                            Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-
                            Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
                            „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h2">
                        <h2>
                            <strong>
                                4. Datenerfassung auf dieser Website
                            </strong>
                        </h2>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Cookies
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf
                            Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung
                            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies
                            werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
                            gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
                            <br></br><br></br>
                            Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-
                            Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von
                            Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
                            <br></br><br></br>
                            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
                            Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige
                            von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken
                            verwendet werden.
                            <br></br><br></br>
                            Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung
                            bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der
                            Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf
                            Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird.
                            Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur
                            technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
                            Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die
                            Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
                            TTDSG); die Einwilligung ist jederzeit widerrufbar.
                            <br></br><br></br>
                            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                            Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen
                            sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
                            Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                            <br></br><br></br>
                            Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser
                            Datenschutzerklärung entnehmen.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Kontaktformular
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                            Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
                            und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
                            Einwilligung weiter.
                            <br></br><br></br>
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                            effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
                            widerrufbar.
                            <br></br><br></br>
                            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
                            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
                            (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
                            insbesondere Aufbewahrungsfristen – bleiben unberührt.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Anfrage per E-Mail, Telefon oder Telefax
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
                            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
                            bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                            <br></br><br></br>
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                            effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
                            widerrufbar.
                            <br></br><br></br>
                            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
                            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
                            (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
                            insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                        </p>
                    </div>
                    <div className="datenschutz_headline_h2">
                        <h2>
                            <strong>
                                5. Soziale Medien
                            </strong>
                        </h2>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Instagram
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden
                            angeboten durch die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
                            Irland.
                            <br></br><br></br>
                            Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät und dem
                            Instagram-Server hergestellt. Instagram erhält dadurch Informationen über den Besuch dieser Website
                            durch Sie.
                            <br></br><br></br>
                            Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des Instagram-Buttons
                            die Inhalte dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser
                            Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
                            Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
                            <br></br><br></br>
                            Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes auf Grundlage von
                            Art. 6 Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine
                            Einwilligung eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres berechtigten
                            Interesses an einer möglichst umfassenden Sichtbarkeit in den Sozialen Medien.
                            <br></br><br></br>
                            Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an
                            Facebook bzw. Instagram weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand
                            Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung
                            verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei ausschließlich
                            auf die Erfassung der Daten und deren Weitergabe an Facebook bzw. Instagram. Die nach der Weiterleitung
                            erfolgende Verarbeitung durch Facebook bzw. Instagram ist nicht Teil der gemeinsamen Verantwortung.
                            Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame
                            Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:
                            https://www.facebook.com/legal/controller_addendum. Laut dieser Vereinbarung sind wir für die Erteilung
                            der Datenschutzinformationen beim Einsatz des Facebook- bzw. Instagram-Tools und für die
                            datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für die
                            Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook verantwortlich. Betroffenenrechte
                            (z. B. Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten können Sie
                            direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
                            verpflichtet, diese an Facebook weiterzuleiten.
                            <br></br>
                            Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.
                            Details finden Sie hier:<br></br><br></br>
                            https://www.facebook.com/legal/EU_data_transfer_addendum,<br></br><br></br>
                            https://privacycenter.instagram.com/policy/ und <br></br><br></br>
                            https://de-de.facebook.com/help/566994660333381.<br></br><br></br>
                            Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram:<br></br><br></br>
                            https://privacycenter.instagram.com/policy/.<br></br><br></br>
                            Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der
                            DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung
                            europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach
                            dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
                            Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:<br></br><br></br>
                            https://www.dataprivacyframework.gov/s/participant-search/participantdetail?
                            contact=true&id=a2zt0000000GnywAAC&status=Active
                        </p>
                    </div>
                    <div className="datenschutz_headline_h2">
                        <h2>
                            <strong>
                                6. Plugins und Tools
                            </strong>
                        </h2>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Google Fonts
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Google Fonts, die von Google
                            bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Fonts in ihren Browsercache,
                            um Texte und Schriftarten korrekt anzuzeigen.
                            <br></br><br></br>
                            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google
                            aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse diese Website
                            aufgerufen wurde. Die Nutzung von Google Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                            Websitebetreiber hat ein berechtigtes Interesse an der einheitlichen Darstellung des Schriftbildes auf seiner
                            Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
                            auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
                            von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
                            Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
                            <br></br><br></br>
                            Wenn Ihr Browser Google Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
                            <br></br><br></br>
                            Weitere Informationen zu Google Fonts finden Sie unter <br></br><br></br>
                            https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google:
                            https://policies.google.com/privacy?hl=de.<br></br><br></br>
                            Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der
                            DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung
                            europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach
                            dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
                            Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:
                            <br></br><br></br>
                            https://www.dataprivacyframework.gov/s/participant-search/participantdetail?
                            contact=true&id=a2zt000000001L5AAI&status=Active
                        </p>
                    </div>
                    <div className="datenschutz_headline_h3">
                        <h3>
                            <strong>
                                Google Maps
                            </strong>
                        </h3>
                    </div>
                    <div className="datenschutz_content">
                        <p>
                            Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited („Google“), Gordon
                            House, Barrow Street, Dublin 4, Irland.
                            <br></br><br></br>
                            Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese
                            Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
                            Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung. Wenn Google Maps aktiviert ist,
                            kann Google zum Zwecke der einheitlichen Darstellung der Schriftarten Google Fonts verwenden. Beim
                            Aufruf von Google Maps lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und
                            Schriftarten korrekt anzuzeigen.
                            <br></br><br></br>
                            Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-
                            Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt
                            ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
                            Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
                            DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
                            Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                            Einwilligung ist jederzeit widerrufbar.
                            <br></br><br></br>
                            Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.
                            Details finden Sie hier:<br></br><br></br>
                            https://privacy.google.com/businesses/gdprcontrollerterms/ und
                            <br></br>
                            https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.
                            <br></br><br></br>
                            Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:
                            <br></br><br></br>
                            https://policies.google.com/privacy?hl=de.
                            <br></br><br></br>
                            Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der
                            DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung
                            europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach
                            dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
                            Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:<br></br>
                            https://www.dataprivacyframework.gov/s/participant-search/participantdetail?
                            contact=true&id=a2zt000000001L5AAI&status=Active
                            <br></br><br></br>
                            Quelle:
                            https://www.e-recht24.de


                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Datenschutz