import React from "react";
import "./home.css";
import { LuClock } from "react-icons/lu"
import { MdLocationPin } from "react-icons/md"
import { FaPhoneAlt } from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import logo from "../../assets/Praxislogo_trenner.png"
import picture_beratung from "../../assets/home_beratung.JPG"
import picture_kinder from "../../assets/pic1.jpg"
import picture_jugendliche from "../../assets/pic3.jpg"


import "animate.css"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";



const Home = () => {

    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
    }, []);

    return (
        <>

            <div className="home_outter_container_block1">
                <div className="home_container_welcome">
                    <span>
                        Herzlich willkommen in der Praxis für Kieferorthopädie Dr. Ch. Medefindt
                    </span>
                    <h1>
                        Kieferorthopädie Wolfenbüttel
                    </h1>
                </div>
            </div>

            <div className="home_outter_container_block2">
                <div className="home_logo_separator">
                    <span>
                        <img src={logo} loading="lazy" alt="logo" />
                    </span>
                </div>
            </div>

            <div className="home_outter_container_block3">
                <div className="home_foreground_img">
                    <div className="home_foreground_img_table">
                        <div className="home_foreground_img_table_left">
                            <p className="home_foreground_img_table_left_headline">
                                <LuClock className="home___clock" style={{ verticalAlign: "text-bottom" }}> </LuClock> Öffnungszeiten
                            </p>
                            <table className="home_foreground_img_table_left_content">
                                <tbody>
                                    <tr>
                                        <td>
                                            Montag:
                                        </td>
                                        <td>
                                            8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Dienstag:
                                        </td>
                                        <td>
                                            8:30 - 12:00 Uhr & 13:00 - 18:00 Uhr
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Mittwoch:
                                        </td>
                                        <td>
                                            8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Donnerstag:&nbsp; &nbsp; &nbsp;
                                        </td>
                                        <td>
                                            8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            oder nach Vereinbarung
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="home_foreground_img_table_right">
                            <p className="home_foreground_img_table_right_headline">
                                <MdLocationPin className="home___location" style={{ verticalAlign: "text-bottom" }}> </MdLocationPin> Kontakt
                            </p>
                            <table className="home_foreground_img_table_right_content">
                                <tbody>
                                    <tr>
                                        <td>
                                            Praxis für Kieferorthopädie Dr. Ch. Medefindt
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            In den Schönen Morgen 10
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            38300 Wolfenbüttel
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FaPhoneAlt size={25} color="white" style={{ verticalAlign: "middle" }}> </FaPhoneAlt> &nbsp; <a href="tel:+49 5331 6041"> +49 5331 6041 </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <TfiEmail size={25} color="white" style={{ verticalAlign: "middle" }}> </TfiEmail> &nbsp; <a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> info@wolfenbuettel-kieferorthopaedie.de </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home_outter_container_block4">
                <div className="home_inner_container_block4">
                    <div className="home_inner_container_table_content">
                        <p>
                            <LuClock className="inv_home___clock" style={{ verticalAlign: "text-bottom" }}> </LuClock> Öffnungszeiten
                        </p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Montag:
                                    </td>
                                    <td>
                                        8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Dienstag:
                                    </td>
                                    <td>
                                        8:30 - 12:00 Uhr & 13:00 - 18:00 Uhr
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Mittwoch:
                                    </td>
                                    <td>
                                        8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Donnerstag:&nbsp; &nbsp; &nbsp;
                                    </td>
                                    <td>
                                        8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        oder nach Vereinbarung
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="home_inner_container_table_content">
                        <p>
                            <MdLocationPin className="inv_home___location" style={{ verticalAlign: "text-bottom" }}> </MdLocationPin> Kontakt
                        </p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Praxis für Kieferorthopädie Dr. Ch. Medefindt
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        In den Schönen Morgen 10
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        38300 Wolfenbüttel
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <FaPhoneAlt className="inv_icons" color="white" style={{ verticalAlign: "middle" }}> </FaPhoneAlt> &nbsp; <a href="tel:+4953316041"> +49 5331 6041 </a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <TfiEmail className="inv_icons" color="white" style={{ verticalAlign: "middle" }}> </TfiEmail> &nbsp; <a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> info@wolfenbuettel-kieferorthopaedie.de </a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="home_outter_container_block5">
                <div className="home_inner_container_block5">
                    <div className="home_inner_container_bg_img">
                        <div className="home_inner_container_headline">
                            <p>
                                Wir freuen uns über Ihren Besuch auf unserer Website!
                            </p>
                        </div>
                        <div className="home_inner_container_block5_content">
                            <p>
                                Liebe Patienten, auf unserer Website möchten wir Ihnen die
                                Möglichkeit geben, mehr über unsere Praxis, unser Team und vor
                                allem unsere Leistungen zu erfahren. Wir freuen uns Sie schon
                                bald in unserer Praxis begrüßen zu dürfen.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div id="separator" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <div className="home_outter_container_block6">
                    <div className="home_inner_container_block6_headline">
                        <p>
                            Von Anfang an gut beraten...
                        </p>
                    </div>
                </div>

                <div className="home_outter_container_block6_content">
                    <div className="home_inner_container_block6_content">
                        <div className="home_inner_container_content_section1">
                            <p>
                                Als spezialisierte Fachzahnarztpraxis für Kieferorthopädie
                                liegt es uns am Herzen, dass Sie sich von Anfang an gut beraten
                                fühlen. All unsere Behandlungen werden individuell auf Sie angepasst.
                                Schon bei Ihrem ersten Termin stehen zunächst Ihre individuellen
                                Wünsche und Vorstellungen im Mittelpunkt. Bei der
                                kieferorthopädischen Erstuntersuchung werden wir die Zahnstellung
                                in Ober- und Unterkiefer sowie den Biss überprüfen.
                                Neben der Feststellung, ob eine kieferorthopädische Behandlung
                                zum gegebenen Zeitpunkt sinnvoll ist, geben auch wir Ihnen
                                hierbei die Möglichkeit uns persönlich kennen zu lernen und
                                gehen auf Ihre Bedenken und Fragen ein. Wir behandeln stets mit
                                modernster Technik und nach dem neusten Stand der Wissenschaft.
                                Bereits zu Beginn der kieferorthopädischen Behandlung ist uns
                                das funktionelle Zusammenspiel der Zahn- und Kieferstellung
                                mindestens genauso wichtig wie ein strahlendes Lächeln.
                            </p>
                        </div>
                        <div className="home_inner_container_block6_image">
                            <img src={picture_beratung} alt="kfo_beratung" loading="lazy">
                            </img>
                        </div>
                    </div>
                </div>

                <div className="home_separator1">
                </div>
            </div>

            <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <div className="home_outter_container_block7">
                    <div className="home_inner_container_block7_headline_content">
                        <p>
                            Unsere Philosophie: <br></br>"Mehr als nur ein schönes Lächeln"
                        </p>
                    </div>
                </div>
                <div className="home_outter_container_block7_content" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                    <div className="home_inner_container_block7_content_bg_img">
                        <div className="home_inner_container_block7_bg_img">
                            <img src={picture_jugendliche} alt="picture_jugendliche" loading="lazy"></img>
                        </div>
                        <div className="home_inner_container_block7_content_content">
                            <p>
                                Unser Anspruch ist eine effiziente, für den Patienten optimal
                                abgestimmte Behandlung und ein zu 100 Prozent überzeugendes Ergebnis.
                                Hierbei spielt die Funktionalität, wie eine gesunde Kaufunktion, optimale
                                Aussprache und optimale Zahnhygiene eine ebenso wichtige Rolle wie die
                                Ästhetik. Unser Ziel ist ein optimales Zusammenspiel des
                                Zahn-Mund-Kiefersystems zu schaffen. Durch die Spezialisierung zur
                                Fachzahnärztin für Kieferorthopädie hat Frau Dr. Medefindt nicht nur das
                                Zahnmedizinstudium erfolgreich abgeschlossen, sondern auch eine dreijährige
                                Vollzeitweiterbildung mit einer anschließenden Fachzahnarztprüfung absolviert.
                                Sie ist damit spezialisiert auf die Behandlung von Zahn- und Kieferfehlstellungen
                                sowohl bei Kindern als auch Erwachsenen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="separator" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                <div className="home_separator2">
                </div>

                <div className="home_outter_container_block6">
                    <div className="home_inner_container_block6_headline">
                        <p>
                            Ob Klein oder Groß: <br></br>Jeder ist bei uns herzlich willkommen
                        </p>
                    </div>
                </div>

                <div className="home_outter_container_block6_content">
                    <div className="home_inner_container_block6_content">
                        <div className="home_inner_container_content_section1">
                            <p>
                                Ob eine Behandlung mit herausnehmbarer Zahnspange,
                                festsitzender Zahnspange oder mit durchsichtigen Alignern, wir
                                bieten Ihnen alle Möglichkeiten der klassischen Kieferorthopädie
                                verbunden mit neuen, modernen Methoden bei höchster fachlicher
                                Qualität. Schöne, gerade Zähne sind keine Frage des Alters. Sie sind
                                wichtig für eine bestmögliche Zahnreinigung und gesunde Kaufunktion.
                            </p>
                        </div>
                        <div className="home_inner_container_block6_image">
                            <img src={picture_kinder} alt="kfo_beratung" loading="lazy"></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home_team_praxis_leistungen_container">
                <div className="home_team_praxis_leistungen_container_bg" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">

                    <div className="home_box">
                        <a href="/praxis">
                            <span className="home_box_span">
                            </span>
                            Unsere Praxis
                        </a>
                    </div>

                    <div className="home_box">
                        <a href="/team">
                            <span className="home_box_span">
                            </span>
                            Unser Team
                        </a>
                    </div>

                    <div className="home_box">
                        <a href="/leistungen">
                            <span className="home_box_span">
                            </span>
                            Unsere Leistungen
                        </a>
                    </div>
                </div>
            </div>

            <div className="genContainerFooter">
                <div className="genContainerFooter_inner">
                    <p>
                        Haben Sie noch Fragen? <br></br> Rufen Sie uns gerne an
                    </p>
                </div>
            </div>

        </>
    )
}

export default Home