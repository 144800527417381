import React from "react";
import "./praxis.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from "react-responsive-carousel";
import "react-image-gallery/styles/css/image-gallery.css";
import Gallery from "./Gallery";
//import ImageGallery from "react-image-gallery";
//import img1 from "../../assets/pic1.png";
//import img2 from "../../assets/pic2.png";
//import img3 from "../../assets/pic3.png";


/*const imgData = [
    {
        "id": 1,
        "alt": "img1",
        "title": "title1",
        "image": img1
    },
    {
        "id": 2,
        "alt": "img2",
        "title": "title2",
        "image": img2
    },
    {
        "id": 3,
        "alt": "img3",
        "title": "title3",
        "image": img3
    }
];
*/

const Praxis = () => {

    return (
        <>
            <div className="praxis_headline">
                <div className="praxis__headline">
                    <h1> Einblicke in unsere KFO-Praxis </h1>
                </div>
            </div>

            <div className="praxis__intro">
                <div className="praxis___intro">
                    <div className="praxis____intro">
                        <p>
                            Unsere Praxis verfügt über modernste technische Ausstattungen.
                            Mit unserer digitalen Röntgenanlage haben wir optimale Diagnosemöglichkeiten
                            bei minimaler Strahlenbelastung. Mittels unseres Intraoralscanners ist es oft möglich,
                            ganz auf konventionelle Abdrücke mit herkömmlicher Abdruckmasse zu verzichten.
                        </p>
                    </div>
                </div>
            </div>

            <Gallery />

            <div className="genContainerFooter">
                <div className="genContainerFooter_inner">
                    <p>
                        Termine können einfach und schnell auch online gebucht werden <br></br>

                        <a href="https://iie-systems.de/online-termin-kfo-medefindt" target="_blank" rel="noreferrer">
                            <button title="onlineAppointments" className="praxis_btn">
                                Termin online buchen
                            </button>
                        </a>

                        {/*
                            <button title="onlineAppointments" className="praxis_btn" onClick={window.toggleDrFlexAppointments}>
                                Termin online buchen
                            </button>
                        */}
                    </p>
                </div>
            </div >

            {/*<div className="outter_container">
                <div className="inner_container">
                    <Carousel className="carouselStyle"
                        autoPlay
                        showStatus="false"
                        width="100%"
                        stopOnHover="true"
                        swipeable="true"
                        axis="horizontal"
                        useKeyboardArrows="true"
                        showThumbs="false"
                        emulateTouch="true"
                        infiniteLoop="true"
                        interval="3000"
                        preventMovementUntilSwipeScrollTolerance="true">
                        {imgData.map(data => {
                            return (
                                <div className="carouselContainer" key={data.id}>
                                    <img src={data.image} alt={data.alt} title={data.title} />
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
                    </div>*/}
        </>
    )
}

export default Praxis