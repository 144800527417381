import React from "react";
import {
Navbar,
Home,
Praxis,
Team,
Leistungen,
Impressum,
Datenschutz,
Footer,
Kontakt,
Buttons,
Stellenausschreibungen
} from "./components";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
/*import CookieConsent from "react-cookie-consent";*/
/*import {Notification} from "./components";*/

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const App = () => {

    useEffect(() => {
        AOS.init({ once: true });
        AOS.refresh();
    }, []);

    return (
        <Router>

            <div className="App">

                
                {/*<div className="app_notification" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000">
                    <Notification />
                </div>*/}
                

                <div className="app_navbar">
                    <Navbar />
                </div>

                <div className="app_button">
                    <div className="app_button_sub">
                        <Buttons />
                    </div>
                </div>

                <Switch>

                    <Route exact path="/">
                        <Home />
                    </Route>

                    <Route path="/Home">
                        <Home />
                    </Route>

                    <Route path="/team">
                        <Team />
                    </Route>

                    <Route path="/praxis">
                        <Praxis />
                    </Route>

                    <Route path="/leistungen">
                        <Leistungen />
                    </Route>

                    <Route path="/impressum">
                        <Impressum />
                    </Route>

                    <Route path="/datenschutzerklaerung">
                        <Datenschutz />
                    </Route>

                    <Route path="/kontakt">
                        <Kontakt />
                    </Route>

                    <Route path="/stellenausschreibungen">
                        <Stellenausschreibungen />
                    </Route>

                    {/*<Route path="/download">
                        <Download />
                    </Route>*/}

                </Switch>

                <div className="app_footer">
                    <Footer />
                </div>

            </div>

        </Router>
    )
}

export default App