import React from "react";
import { TfiEmail } from "react-icons/tfi";
import "./stellenausschreibungen.css"

const Stellenausschreibungen = () => {
    return (
        <>
            <div className="jobOffers_container_headline">
                <div className="jobOffers_headline">
                    <h1>
                        <span>Zahntechniker/in</span> (m/w/d) Teilzeit
                    </h1>
                </div>
            </div>

            <div className="jobOffers_container">
                <div className="jobOffers__container">
                    <div className="jobOffers__picture">
                        <p>
                            <strong>Neueröffnung einer kieferorthopädischen Fachpraxis!</strong> <br></br>
                            Damit unser Team vollständig wird, suchen wir ab August ´24 eine/n engagierte/n Zahntechniker*in (m/w/d) im Fachbereich KFO,
                            die/der mit Freude in diesem Beruf arbeitet und Spaß am digitalen Workflow hat.
                        </p>
                    </div>
                </div>
            </div>

            <div className="skills_container">
                <div className="skills__container_AN_AG">
                    <div className="skills__container_AN_AG_content">
                        <span>Was Sie mitbringen:</span>
                        <br></br>
                        <ul>
                            <li> abgeschlossene Ausbildung als Zahntechniker </li>
                            <li> ein hohes Maß an Motivation und Qualitätsbewusstsein </li>
                            <li> Teamfähigkeit, Verantwortungsbewusstsein und Zuverlässigkeit </li>
                            <li> technisches Grundverständnis </li>
                            <li> Sinn für Ästhetik </li>
                        </ul>
                    </div>
                    <div className="skills__container_AN_AG_content">
                        <span>Wir bieten: </span>
                        <br></br>
                        <ul>
                            <li> überdurchschnittliches Gehalt, das Ihre Leistung angemessen würdigt </li>
                            <li> Fort- und Weiterbildungen </li>
                            <li> flexible Arbeitszeiten </li>
                            <li> langfristiges und sicheres Arbeitsumfeld mit Perspektive </li>
                            <li> modernes Arbeitsumfeld (digitales Arbeiten inkl. 3D Druck) </li>
                            <li> eine harmonische Arbeitsatmosphäre</li>
                            <li> regelmäßige Teamevents </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="jobOffers_container">
                <div className="jobOffers__container">
                    <div className="jobOffers__picture">
                        <p>
                            Sollten wir Ihr Interesse geweckt haben, schicken Sie uns einfach eine Email mit einer kurzen Vorstellung und Ihrem Lebenslauf.
                        </p>
                    </div>
                </div>
            </div>

            <div className="apply_mail">
                <a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> <TfiEmail className="apply_email_icon" style={{ verticalAlign: "middle" }}> </TfiEmail> </a> <a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> &nbsp; Jetzt bewerben </a>
            </div>

            <div className="genContainerFooter">
                <div className="genContainerFooter_inner">
                    <p>
                    Wir freuen uns Sie bald kennen zu lernen!
                    </p>
                </div>
            </div>
        </>
    )
}

export default Stellenausschreibungen