import React from "react";
import "./footer.css";
import { FaPhoneAlt } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import logo2 from "../../assets/Praxislogo2.png";
import ScrollToTop from "react-scroll-to-top";

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <>
            <footer className="background_footer">

                <div className="subcontainer_background_footer">
                    <div className="border_logo">
                        <div className="infobox_footer">
                            <div className="oeffnungszeiten_headline">
                                Öffnungszeiten
                            </div>

                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            Mo:
                                        </td>
                                        <td>
                                            8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Di:
                                        </td>
                                        <td>
                                            8:30 - 12:00 Uhr & 13:00 - 18:00 Uhr
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Mi:
                                        </td>
                                        <td>
                                            8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Do:
                                        </td>
                                        <td>
                                            8:30 - 12:00 Uhr & 13:00 - 17:00 Uhr
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            oder nach Vereinbarung
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div className="infobox_footer">
                            <h1>Kieferorthopädie in Wolfenbüttel</h1>
                            <h2>Praxis für Kieferorthopädie</h2>
                            <h3>Fachzahnärztin Dr. Christine Medefindt</h3>
                            <p>
                                In den Schönen Morgen 10
                            </p>
                            <p>
                                38300 Wolfenbüttel
                            </p>
                        </div>

                    </div>

                    <div className="container_icon_mail">
                        <p>
                            <TfiEmail className="email_icon" size={25} style={{ verticalAlign: "middle" }}> color={"#056b70ff"} </TfiEmail><a href="mailto: info@wolfenbuettel-kieferorthopaedie.de"> info@wolfenbuettel-kieferorthopaedie.de </a> <br></br>
                        </p>
                    </div>

                    <div className="container_icon_phone">
                        <p>
                            <FaPhoneAlt className="phone_icon" size={25} style={{ verticalAlign: "middle" }}> </FaPhoneAlt><a href="tel:+4953316041"> +49 5331 6041 </a><br></br>
                        </p>
                    </div>

                    <div className="invisible_container_datenschutzerklärung_impressum">
                        <p><a href="/datenschutzerklaerung">Datenschutzerklärung</a></p>
                        <p><a href="/impressum">Impressum</a></p>
                    </div>

                    <div className="container_datenschutz_impressum">
                        <div className="container_datenschutz">
                            <p><a href="/datenschutzerklaerung">Datenschutzerklärung</a></p>
                        </div>

                        <div className="container_impressum">
                            <p><a href="/impressum">Impressum</a></p>
                        </div>
                    </div>

                    <div className="footer_instagram">
                        <ul className="wrapper">
                            <li className="icon instagram">
                                <span className="tooltip">kfo_medefindt</span>
                                <span><i className="fab fa-instagram"><a href="https://www.instagram.com/kfo_medefindt/"><FaInstagram className="insta_icon_desktop"></FaInstagram></a></i></span>
                            </li>
                        </ul>
                    </div>

                    <div className="footer_img_logo_container">
                    </div>

                    <div className="footer_img_logo_container2" onClick={scrollToTop}>
                        <img className="Logo_footer" src={logo2} alt="logo" />
                    </div>

                    <ScrollToTop className="scrolltotop" smooth color={"white"}> </ScrollToTop>

                </div>
            </footer>
            <div className="copyright_container">
                <p>
                    <span>&copy;</span> 2024 Dr. med. dent. Medefindt
                </p>
            </div>
        </>
    )
}

export default Footer